import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Drawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import useAuth from '../../hooks/useAuth';

const sections = [
  {
    id: '0',
    items: [
      {
        title: 'Analytics',
        path: '/',
      }
    ]
  },
  {
    id: '1',
    items: [
      {
        title: 'Conteúdos',
        path: '/conteudos',
        children: [
          {
            title: 'Profissões',
            path: '/conteudos/profissoes'
          },
          {
            title: 'Competências',
            path: '/conteudos/competencias'
          },
          {
            title: 'Formações',
            path: '/conteudos/formacoes'
          },
          {
            title: 'Conhecer-me',
            path: '/conteudos/conhecer-me'
          },
          {
            title: 'Concelhos',
            path: '/conteudos/concelhos'
          },
          {
            title: 'Mercado Trabalho',
            path: '/conteudos/mercado-trabalho',
            children: [
              {
                title: 'Artigos',
                path: '/conteudos/mercado-trabalho/artigos',
              },
              {
                title: 'Categorias',
                path: '/conteudos/mercado-trabalho/categorias',
              }
            ]
          },
          {
            title: 'Casos de Sucesso',
            path: '/conteudos/casos-de-sucesso'
          }
        ]
      }
    ]
  },
  {
    id: '2',
    items: [
      {
        title: 'Publicidade',
        path: '/publicidade',
      }
    ]
  },
  {
    id: '3',
    items: [
      {
        title: 'Acessos',
        path: '/acessos',
      }
    ]
  },
  {
    id: '4',
    items: [
      {
        title: 'Utilizadores',
        path: '/utilizadores',
      }
    ]
  },
  {
    id: '5',
    items: [
      {
        title: 'Homepage',
        path: '/homepage',
      }
    ]
  },
  {
    id: '6',
    items: [
      {
        title: 'Tutoriais',
        path: '/tutoriais',
      }
    ]
  },
  {
    id: '7',
    items: [
      {
        title: 'Perfil',
        path: '/perfil',
      }
    ]
  },
];

const SideMenuSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { logout } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const userLogout = () => {
    logout();
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#150555'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              px: 2
            }}
          >
            <Box
              component={RouterLink}
              to="/"
            >
              <Logo
                sx={{
                  height: 30,
                  maxWidth: 155
                }}
                logo="white"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.id}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 1
                }
              }}
              {...section}
            />
          ))}
          <Button
            variant="outlined"
            sx={{ color: 'white', borderColor: 'white' }}
            onClick={userLogout}
          >
            Logout
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: '100% !important',
            width: 220
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideMenuSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default SideMenuSidebar;
