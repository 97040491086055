import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let disableAppInsight = true;
if (process.env.REACT_APP_INSIGHTS_KEY) {
  disableAppInsight = false;
}
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
    disableTelemetry: disableAppInsight,
    disableFetchTracking: disableAppInsight,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAutoRouteTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((envelope) => {
  envelope.tags = envelope.tags || [];
  envelope.tags.push({ 'ai.cloud.role': 'mymentorBO' });
});
export { reactPlugin, appInsights };
