import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import SideMenuNavbar from './SideMenuNavbar';
import SideMenuSidebar from './SideMenuSidebar';

const SideMenuLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const SideMenuLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '220px',
    paddingTop: '0px'
  }
}));

const SideMenuLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const SideMenuLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const SideMenuLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  return (
    <SideMenuLayoutRoot>
      <SideMenuNavbar
        onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
      />
      <SideMenuSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <SideMenuLayoutWrapper>
        <SideMenuLayoutContainer>
          <SideMenuLayoutContent
            className="dashboardLayoutContent"
          >
            <Outlet />
          </SideMenuLayoutContent>
        </SideMenuLayoutContainer>
      </SideMenuLayoutWrapper>
    </SideMenuLayoutRoot>
  );
};

export default SideMenuLayout;
