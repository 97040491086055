import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Typography } from '@material-ui/core';
import { LoginJWT } from '../../components/authentication/login';
import Logo from '../../components/Logo';

const Login = () => {
  const pageName = `Login | ${process.env.REACT_APP_TITLE}`;
  return (
    <>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      <Box
        sx={{
          background: 'linear-gradient(257.68deg, rgba(192, 35, 239, 0.8) 0%, rgba(62, 0, 131, 0.8) 72.88%, rgba(62, 0, 131, 0.8) 93.22%)',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          position: 'relative',
          zIndex: 1
        }}
      >
        <Box sx={{
          backgroundImage: 'url(/static/images/studentsBackground.png)',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: -1,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
        />
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <Logo
              sx={{
                height: 60,
                maxWidth: 300
              }}
              logo="white"
            />
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                sx={{ textAlign: 'center' }}
              >
                Login
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <LoginJWT />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
