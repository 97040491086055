import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';

import SideMenuLayout from './components/SideMenuLayout/SideMenuLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Main Pages
const Training = Loadable(lazy(() => import('./pages/content/Training')));
const Professions = Loadable(lazy(() => import('./pages/content/Professions')));
const Skills = Loadable(lazy(() => import('./pages/content/Skills')));
const KnowMe = Loadable(lazy(() => import('./pages/content/KnowMe')));
const Counties = Loadable(lazy(() => import('./pages/content/Counties')));
const JobMarketArticles = Loadable(lazy(() => import('./pages/content/JobMarketArticles')));
const JobMarketCategories = Loadable(lazy(() => import('./pages/content/JobMarketCategories')));
const SuccessStories = Loadable(lazy(() => import('./pages/content/SuccessStories')));
const Publicity = Loadable(lazy(() => import('./pages/Publicity')));
const Permissions = Loadable(lazy(() => import('./pages/Permissions')));
const UsersData = Loadable(lazy(() => import('./pages/UsersData')));
const HomePage = Loadable(lazy(() => import('./pages/HomePage')));
const Profile = Loadable(lazy(() => import('./pages/Profile')));
const Tutorials = Loadable(lazy(() => import('./pages/Tutorials')));
const MyMentorAnalytics = Loadable(lazy(() => import('./pages/MyMentorAnalytics')));

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

const routes = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <SideMenuLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <MyMentorAnalytics />
      },
      {
        path: '/conteudos/profissoes',
        element: <Professions />
      },
      {
        path: '/conteudos/competencias',
        element: <Skills />
      },
      {
        path: '/conteudos/formacoes',
        element: <Training />
      },
      {
        path: '/conteudos/conhecer-me',
        element: <KnowMe />
      },
      {
        path: '/conteudos/concelhos',
        element: <Counties />
      },
      {
        path: '/conteudos/mercado-trabalho/artigos',
        element: <JobMarketArticles />
      },
      {
        path: '/conteudos/mercado-trabalho/categorias',
        element: <JobMarketCategories />
      },
      {
        path: '/conteudos/casos-de-sucesso',
        element: <SuccessStories />
      },
      {
        path: 'publicidade',
        element: <Publicity />
      },
      {
        path: 'acessos',
        element: <Permissions />
      },
      {
        path: 'utilizadores',
        element: <UsersData />
      },
      {
        path: 'homepage',
        element: <HomePage />
      },
      {
        path: 'perfil',
        element: <Profile />
      },
      {
        path: 'tutoriais',
        element: <Tutorials />
      }
    ]
  }
];

export default routes;
