import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
import useScrollReset from './hooks/useScrollReset';
import useScrollResetContainer from './hooks/useScrollResetContainer';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from './theme';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();
  useScrollResetContainer('.dashboardLayoutContent');

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-center" />
          {auth.isInitialized ? content : <SplashScreen />}
        </RTL>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
