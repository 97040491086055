import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, IconButton, Toolbar, Box } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuIcon from '../../icons/Menu';
import Logo from '../Logo';

const SideMenuNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: 'white',
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const SideMenuNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <SideMenuNavbarRoot
      {...other}
      sx={{ display: { lg: 'none' } }}
    >
      <Toolbar sx={{ minHeight: 64, display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{ display: 'flex' }}
        >
          <IconButton
            onClick={onSidebarMobileOpen}
            sx={{
              color: '#C64DEC'
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box
            component={RouterLink}
            to="/"
          >
            <Logo
              sx={{
                height: 30,
                maxWidth: 150
              }}
            />
          </Box>
        </Box>
      </Toolbar>
    </SideMenuNavbarRoot>
  );
};

SideMenuNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default SideMenuNavbar;
